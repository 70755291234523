import "../styles/mui.scss";
import "../styles/globals.css"
import doodad from "../content/img/doodad_03.png"
import line4 from "../content/img/Line_04.png"
import line2 from "../content/img/Line_02.png"

import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { scroller } from "react-scroll";
import { StaticQuery, graphql } from "gatsby";
import React from "react";

const HeaderDivCSS = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  padding-top: 8vh;
  justify-content: center;
  align-items: 'center';
`

const LogoContainerCSS = css`
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  @media (max-height: 420px) {
    margin-top: 12px;
    margin-bottom: 12px;
  }
`

const LogoCSS = css`
  width: 50vh;
  max-width: 80vw;
  min-height: auto;
  cursor: pointer;

`

const TaglineContainerCSS = css`
  display: flex;
  align-items: center;
  justify-content: center;
`

const TaglineCSS = css`
text-align: center;
font-size: 2em;
line-height: 0.9;
color: #fafafa;
font-family: 'LondrinaSolid';
letter-spacing: 2px;
margin: 0px 16px;
text-shadow: 0px 4px 2px #00000095, 0px -2px 8px #00000095;
text-transform: uppercase;


@media (max-height: 420px) {
  font-size: 1.8em;
}

@media (max-width: 740px) {
  width: 336px;
}

@media (max-width: 380px) {
  width: 260px;
}
`

const DoodadCSS = css`
  @media (max-width: 740px) {
    display: none;
  }
`

const LineCSS = css`
  margin-top: 8px;
  margin: 9px 48px 0px;
  height: 9px;
  background-image: url(${line4}) ;
  background-size: 100%;
  @media (max-width: 740px) {
    width: 336px;
    background-image: url(${line2}) ;
  }
  @media (max-width: 380px) {
    width: 260px;
  }
`

export const HeaderContent: React.FC = () => {
  return <StaticQuery
    query={graphql`
      query {
        logo: file(relativePath: { eq: "img/logo.png" }) {
          publicURL
        }
      }
  `}
    render={data => {

      return <HeaderDivCSS>
        <div css={[LogoContainerCSS]} onClick={() => {
          scroller.scrollTo("home", {
            duration: 600,
            smooth: true,
            offset: 0
          })
        }}>
          <img draggable={false} css={[LogoCSS]} src={data.logo.publicURL} />
        </div>
        <div css={TaglineContainerCSS}>
          <img css={DoodadCSS} src={doodad} />
          <div css={[TaglineCSS]}>Discover the ideas that shaped humanity</div>
          <img css={DoodadCSS} src={doodad} />
        </div>
        <div css={LineCSS} />
      </HeaderDivCSS>
    }} />
}
