import React from 'react';
import css from '@emotion/css';
import { colors } from '../styles/colors';
import TopBorder from '../content/img/section_up.png'
import PS4Logo from '../content/img/platform-logos/Logos_PS4.svg'
import PS5Logo from '../content/img/platform-logos/Logos_PS5.svg'
import NXLogo from '../content/img/platform-logos/Logos_NX.svg'
import XboxLogo from '../content/img/platform-logos/Logos_Xbox.svg'
import SteamLogo from '../content/img/platform-logos/Logos_Steam.svg'
import config from '../website-config';

const outerContainerCSS = css`
position: relative;
padding: 8px;
margin: 0px -4vw;
background: ${colors.logoOrange};
min-height: 17vh;
color: #fafafa;
/* padding: 32px; */
display: flex;
justify-content: center;
align-items: center;
`

const innerContainerCSS = css`
  width: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    margin: 0px 8px;
  }
  @media (max-width: 830px) {
    flex-wrap: wrap;
    img {
      margin: 0px;
    }
  }
`

const psContainerCSS = css`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    margin: 0px 8px;
  }
  @media (max-width: 830px) {
    flex-wrap: wrap;
    img {
      margin: 0px;
    }
  }
`

const platformLogoCSS = css`
  cursor: pointer;
  height: 60px;
  @media (max-width: 830px) {
    height: 40px;
  }
`

const borderCSS = css`
position: absolute;
top:-24px;
width: 100%;
height: ${24}px;
background-size: contain;
background-image: url(${TopBorder});
image-rendering: pixelated;
@media (min-width: 640px) {
  top:-48px;
  height: ${24 * 2}px;
}
`;

const PlatformButton: React.FC<{ url: string | undefined, svg: any }> = ({ url, svg }) => {
  return <a href={url || ""} target='_blank'>
    <img css={platformLogoCSS} src={svg} />
  </a>
}

export const InfoSection: React.FC = () => {
  return (
    <div css={outerContainerCSS} >
      <div css={borderCSS} />
      <div css={innerContainerCSS}>
          <PlatformButton url={config.steam} svg={SteamLogo} />
          <PlatformButton url={config.nx} svg={NXLogo} />
          <PlatformButton url={config.xbox} svg={XboxLogo} />
          <div css={psContainerCSS}>
            <PlatformButton url={config.ps4} svg={PS4Logo} />
            <PlatformButton url={config.ps5} svg={PS5Logo} />
          </div>
      </div>
    </div>
  );
};
