import { css } from "@emotion/core";
import React from "react";

const styleCSS = css`
    margin: 16px 0px;
    max-width: 616px;
    width: 130vw;
    border-radius: 16px;
`

type Props = {
    storageName: string,
    style?: React.CSSProperties
}

export const StorageImage: React.FC<Props> = (props) => {
    return <img css={styleCSS} src={`https://firebasestorage.googleapis.com/v0/b/roots-of-pacha-production.appspot.com/o/website%2Fimages%2F${props.storageName}.png?alt=media`} />;
}