import { css } from '@emotion/core'

const CutImageHeight = 24;

export const MainStyle = css`
  position: relative;
  padding: ${CutImageHeight}px 0px;
  @media (min-width: 640px) {
    padding: ${CutImageHeight * 2}px 0px;
  }
`

export const BackgroundStyle = css`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: -50%;
  right: -50%;
  background-color: #edf5ef;
  z-index: -1;
`

const CutImageStyle = css`
  width: 100%;
  height: ${CutImageHeight}px;
  background-size: contain;
  image-rendering: pixelated;
  @media (min-width: 640px) {
    height: ${CutImageHeight * 2}px;
  }
`

export const TopCutImageStyle = css`
  position: absolute;
  top: -4px;
  ${CutImageStyle}
`
export const BottomCutImageStyle = css`
  position: absolute;
  bottom: -4px;
  transform: scale(-1);
  ${CutImageStyle}
`