import React from 'react'
import SectionCutImage from './images/section_cut.png'
import PaperTexture from './images/ricepaper2.png'
import { BackgroundStyle, MainStyle, TopCutImageStyle, BottomCutImageStyle } from './styles'

export const DeepSection:React.SFC = (props) => (
    <div css={MainStyle}>
        <div css={BackgroundStyle} style={{ background: `white` }}>
            <div css={TopCutImageStyle} style={{ backgroundImage: `url(${SectionCutImage})` }} />
            <div css={BottomCutImageStyle} style={{ backgroundImage: `url(${SectionCutImage})` }} />
        </div>
        {props.children}
    </div>
)