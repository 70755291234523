import React, { useState } from 'react';
import css from '@emotion/css';
import { colors } from '../styles/colors';
import ReCAPTCHA from "react-google-recaptcha"
import axios from "axios";

const validator = require("email-validator");

const mainCSS = css`
  min-width: 520px;
  @media (max-width: 560px) {
    min-width: 0;
  }
`

const p1 = css`
  font-family: 'LondrinaSolid';
  font-size: 1em;
  text-transform: uppercase;
  color: #5c443b;
  text-align: center;
`

const p2 = css`
  font-family: 'LondrinaSolid';
  font-size: 1.8em;
  text-transform: uppercase;
  text-align: center;
  color: ${colors.logoOrange};
`

const errorCSS = css`
  font-family: 'Rubik';
  background: rgb(204, 42, 36);
  border-radius: 4px;
  color: white;
  padding: 0px 12px;
  margin-top: 4px;  
`

const formCSS = css`
  display: flex;
  margin-top: 12px;
  @media (max-width: 511px) {
    height: auto;
    flex-direction: column;
  }
`

const formInputCSS = css`
  font-family: 'Rubik';
  font-weight: 500;
  margin-right: 16px;
  background: #d7cde5;
  border: 0;
  flex: 1;
  border-radius: 4px;
  padding: 0px 4px;
  color: ${colors.darkgrey};
  text-align: center;
  height: 40px;

  ::placeholder {
    font-weight: 400;
  }

  :disabled {
    opacity: .5;
  }

  @media (max-width: 511px) {
    flex: none;
    margin-right: 0px;
    margin-bottom: 8px;
  }
`;

const formButtonCSS = css`
  height: 40px;
  background: #6f5172;
  font-family: 'LondrinaSolid';
  font-size: 1.2em;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  border-radius: 4px;
  min-width: 118px;
  cursor: pointer;
  user-select: none;
  transition: background-color .25s, border .25s, color .25s;
  border: 2px solid #6f5172;
  
  &.disabled, &.disabled:hover {
    opacity: .5;
    cursor: default;
    background: #6f5172;
    color: white;
  }

  :hover {
    background: white;
    color: #6f5172;
  }

  :active {
    background: white;
    color: #6f5172;
  }

  @media (max-width: 511px) {
    align-self: stretch;
  }
`

const successContainerCSS = css`
  height: 40px;
  background: #6f5172;
  font-family: 'LondrinaSolid';
  font-size: 1.2em;
  color: white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  border-radius: 4px;
  min-width: 118px;
`

//const signupURL = `https://us-central1-roots-of-pacha.cloudfunctions.net/app/signup`;
const signupURL = `/email`;


export const MailSection: React.FC<{}> = () => {
  const recaptchaRef = React.useRef<ReCAPTCHA>(null);
  const [email, setEmail] = useState("");
  const [submiting, setSubmiting] = useState(false);
  const [submited, setSubmited] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [captchaPass, setCaptchaPass] = useState(false);
  const [captchaError, setCaptchaError] = useState(false);

  const failSubmit = () => {
    setCaptchaPass(false);
    setSubmiting(false);
    setSubmitError(true);
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  }

  const submitEmail = () => {
    if (!submiting) {
      setSubmitError(false);

      let canSubmit = true;
      if (!validator.validate(email)) {
        setEmailError(true);
        canSubmit = false;
      }

      if (!captchaPass) {
        setCaptchaError(true);
        canSubmit = false;
      }

      if (canSubmit) {
        setEmailError(false);
        setCaptchaError(false);
        setSubmiting(true);
        var token = recaptchaRef.current != null ? recaptchaRef.current.getValue() : '';
        axios.post(signupURL, {
          token: token,
          email: email
        })
          .then(response => {
            if (response.data.formSubmit) {
              setSubmited(true);
            } else {
              failSubmit();
            }
          }).catch((e) => {
            console.error(e);
            failSubmit();
          });
      }
    }
  }

  const onCaptchaPass = (v: null | string) => {
    setCaptchaPass(v != null);
    setCaptchaError(false);
    setSubmitError(false);
  }

  const onEmailChanged = (v: React.ChangeEvent<HTMLInputElement>) => {
    if (emailError) {
      setEmailError(false);
    }

    if (submitError) {
      setSubmitError(false);
    }

    setEmail(v.target.value)
  }

  return (
    <div css={mainCSS}>
      <div css={p1}>Sign up to</div>
      <div css={p2} style={{ padding: "4px 0" }}>Get Pachan News straight to your inbox</div>
      <div css={p1}>and get a special in-game outfit</div>
      {
        !submited && <React.Fragment>
          <div css={formCSS}>
            <input disabled={submiting} placeholder='Email' css={formInputCSS} type='email' value={email}
              onChange={onEmailChanged}></input>
            <div onClick={submitEmail} draggable={false} css={formButtonCSS} className={submiting ? 'disabled' : ''}>{submiting ? "Sending" : "Sign Up"}</div>
          </div>
          {emailError && <div css={errorCSS}>Please check your email.</div>}
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', marginTop: 12 }}>
            <ReCAPTCHA
              ref={recaptchaRef}
              onChange={onCaptchaPass}
              onErrored={failSubmit}
              sitekey='6LdYyuUoAAAAAMR-vxopSymuHqcaIqHpGnEyyW3X' />
            {captchaError && <div css={errorCSS}>* Captcha required</div>}
            {submitError && <div css={errorCSS}>There was an error. Please try again.</div>}
          </div>
        </React.Fragment>
      }
      {
        submited && <div css={formCSS}>
          <div css={successContainerCSS}>✔ Sign up complete!</div>
        </div>
      }
    </div>
  );
}
