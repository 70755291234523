import { css } from "@emotion/core"
import React from "react"
import { inner } from "../styles/shared"
import { GifVideo } from "./GifVideo"
import { StorageImage } from "./StorageImage"
import { DeepSection } from "./DeepSection"
import { Subheader } from "./Subheader"
import { MailSection } from "./MailSection"

const sectionCSS = css`
  padding-top: 16px;
  padding-bottom: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 680px;

    h1 {
        margin: 0px;
        font-family: "Rubik";
        font-size: 2.5rem;
        font-weight: bold;
    }

    h2 {
        margin: 0px;
        font-family: "Rubik";
        font-size: 2rem;
        font-weight: bold;        
    }
  
    p {
        margin: 0px;
        font-family: "Rubik";
        font-size: 1.8rem;
        text-align: center;
    }
`

export const GameDescription = () => {
    return <DeepSection >
        <div css={[inner, sectionCSS]}>
            <MailSection />
            <Subheader storageName="The_Game" />
            {/* <TrailerVideo /> */}
            <p style={{ margin: "20px 0px" }}><b>Roots of Pacha</b> is a co-op farming and life simulation game set in the stone age, where you and your clan just settled in a promised and fertile land. By connecting with nature at your own pace you’ll help your clan evolve, develop culture and relationships, and discover the mysteries of Pacha, mother nature.</p>
            {/* TUNE TO NATURE */}
            <Subheader storageName="Tune_To_Nature" />
            <StorageImage storageName="01-TuneIntoNature01" />
            <p>Discover, gather, and farm vegetables and herbs. Explore the surrounding wilderness to find animals to befriend and domesticate; fish the shallow and deep waters to see what lies in their depths; and mine the darkest caves filled with mysteries and secret powers.</p>
            <StorageImage storageName="01-TuneIntoNature02" />
            <StorageImage storageName="01-TuneIntoNature03" />
            {/* EVOLVE */}
            <Subheader storageName="Evolve" />
            <StorageImage storageName="02-Evolve01" />
            <p>Begin your journey of discovery as you develop the tools and ideas that reshaped the primitive world. Learn to process and preserve food (meat, vegetarian, and vegan options), begin metalworking, create beautiful pottery and art, unlock your clan’s spirituality, and invent new buildings to expand your village any way you want.</p>
            <StorageImage storageName="02-Evolve02" />
            {/* GET TO KNOW */}
            <Subheader storageName="Get_To_Know_The_Clan" />
            <StorageImage storageName="03-GetToKnowTheClan01" />
            <p>A tight-knit community of people will ensure your village’s survival, each with their own fears and dreams. Develop friendships and overcome hardships together. Grow your village by inviting people from other clans to live with you. Participate in primitive festivals and rituals to Pacha, mother nature, and her elements. And when the time comes, find your true love ❤.</p>           
            <StorageImage storageName="03-GetToKnowTheClan02" />
            {/* MAKE IT YOURS */}
            <Subheader storageName="Make_It_Yours" />
            <StorageImage storageName="04-MakeItYours01" />
            <p>Customize your character’s appearance with the best cosmetics the stone age has to offer. When you’re done working on your face, work on creating the most amazing farm around. Don’t stop there! Design, place, and decorate your own unique home to return to after a hard day’s work.</p>
            <StorageImage storageName="04-MakeItYours02" />
            {/* PLAY WITH FRIENDS */}
            <Subheader storageName="Play_With_Friends" />
            <StorageImage storageName="05-PlayWithFriends" />
            <p>The game was developed from scratch with multiplayer support in mind. Live together with friends and share your talents and resources to help the clan evolve. Ride your favorite animals together. Compete in fishing, have fun at festivals, and participate in milestone multiplayer cutscenes.</p>
        </div>
    </DeepSection>
}