import { css } from "@emotion/core";
import React from "react";

const styleCSS = css`
    margin: 32px 0px 0px;
`

type Props = { 
    storageName: string,
}

export const Subheader: React.FC<Props> = (props) => {
    return <img css={styleCSS} draggable={false} src={`https://firebasestorage.googleapis.com/v0/b/roots-of-pacha-production.appspot.com/o/website%2Fsubheaders%2F${props.storageName}.png?alt=media`} alt={props.storageName} />;
}