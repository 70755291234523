import { css } from "@emotion/core";
import backgroundVideo from '../content/video/BackgroundVideo_6Mbps.mp4'
import { HeaderContent } from "./HeaderContent";
import React from "react";
import { graphql, StaticQuery } from "gatsby";


const containerCSS = css`
height: 78vh;
min-width: 100%;
margin: 0px -4vw;
margin-top: 56px;
position: relative;
overflow: hidden;
display: flex;
justify-content: center;
align-items: start;
@media (max-height: 420px) {
    height: 70vh;
}
`;

const videoCSS = css`
position: absolute;
height: 100%;
width: 100%;
z-index: -2;
object-fit: cover;
object-position: center;
`

const videoOverlayCSS = css`
position: absolute;
height: 100%;
width: 100%;
z-index: -1;
background: linear-gradient(0deg, rgba(0,0,0,.25) 0%, rgba(0,0,0,0) 10%);
`

const VideoHeader: React.FC = () => {
    return (
        <StaticQuery
            query={graphql`
                query {
                    poster: file(relativePath: { eq: "video/BackgroundVideo_6Mbps.jpg" }) {
                    publicURL
                    }
                }
            `}

            render={data => {
                return <div css={containerCSS}>
                    <div css={videoOverlayCSS} />
                    <video css={videoCSS} poster={data.poster.publicURL} autoPlay loop muted playsInline id="background-video">
                        <source src={backgroundVideo} type="video/mp4" />
                    </video>
                    <HeaderContent />
                </div>
            }}
        />
    );
};

export default VideoHeader;
